import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { ReactComponent as LogoSVG } from "svg/logo.svg";

import mouse from "svg/mouseArrow.svg";
import pointer from "svg/pointer.svg";
import media from "src/styles/media";

const MinimalFooter: React.FC<{}> = () => {
  return (
    <Wrapper>
      <MainContent>
        <CompanyInfo>
          <Link aria-label="home" to="/">
            <Logo />
          </Link>
        </CompanyInfo>
      </MainContent>
      <Line>
        © Bitski, Inc.
        <LegalLinks>
          <LinkExternal
            href={
              "https://www.notion.so/otl/TOS-2-1-21-1f9a98659e6446479479339a23185d2f"
            }
          >
            Terms of Use
          </LinkExternal>
          <LinkExternal
            href={
              "https://www.notion.so/otl/Privacy-Policy-c166160b6c544e909dd04176f43081d6"
            }
          >
            Privacy Policy
          </LinkExternal>
        </LegalLinks>
      </Line>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  cursor: url(${mouse}), auto;
  button,
  a,
  input[type="submit"] {
    cursor: url(${pointer}), auto !important;
  }
  height: 19vw;
  border-top: solid 1px white;
  padding: 6.2vw 6.94vw 0;
  background-color: black;
  ${media.tablet} {
    height: 50vw;
    padding: 19.3vw 4.8vw 0;
  }
`;
const MainContent = styled.div`
  display: flex;
  align-items: flex-start;
  ${media.tablet} {
    flex-wrap: wrap;
  }
`;
const CompanyInfo = styled.div`
  width: 11.7vw;
  margin-right: 40vw;
  ${media.tablet} {
    order: 5;
    width: 40.8vw;
  }
`;
const Logo = styled(LogoSVG)`
  width: 7.2vw;
  ${media.tablet} {
    width: 25.1vw;
  }
`;
const LinkExternal = styled.a`
  color: white;
`;
const Line = styled.div`
  width: 86.1vw;
  border-top: solid 1px white;
  margin-top: 8vw;
  display: flex;
  padding-top: 0.9vw;
  justify-content: space-between;
  font-size: 0.7vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 6.5vw;
    padding-top: 7.2vw;
    font-size: 2.4vw;
  }
`;
const LegalLinks = styled.div`
  display: flex;
  width: 12.9vw;
  justify-content: space-between;
  ${media.tablet} {
    width: 42.5vw;
  }
`;

export default MinimalFooter;
