import React from "react";
import styled from "styled-components";

import Header from "./header";

import { ResizeProvider } from "../context/ResizeContext";
import Footer from "./Footer";
import MinimalFooter from "./MinimalFooter";
import MinimalHeader from "./MinimalHeader";

import mouse from "svg/mouseArrow.svg";
import pointer from "svg/pointer.svg";
import media from "src/styles/media";

const Layout: React.FC<{
  landingPage?: boolean;
  minimalBranding?: boolean;
}> = ({ children, landingPage, minimalBranding }) => {
  if (minimalBranding) {
    return (
      <ResizeProvider>
        <Main>{children}</Main>
        <MinimalFooter />
      </ResizeProvider>
    );
  } else {
    return (
      <ResizeProvider>
        {landingPage ? <MinimalHeader /> : <Header />}
        <Main>{children}</Main>
        <Footer />
      </ResizeProvider>
    );
  }
};

const Main = styled.main`
  min-height: calc(100vh - 200px);
  width: 100vw;
  max-width: 100%;
  // overflow: hidden;
  cursor: url(${mouse}), auto;
  button,
  a,
  input[type="submit"] {
    cursor: url(${pointer}), auto;
  }
  ${media.tablet} {
    width: 100vw;
    overflow: hidden;
  }
`;

export default Layout;
