import media from "src/styles/media";
import styled from "styled-components";

import Colors from "styles/Colors";
import pointer from "svg/pointer.svg";

export const NavButton = styled.button`
  width: 9.2vw;
  height: 3.5vw;
  color: black;
  background: white;
  border: none;
  font-size: 1.2vw;
  ${media.tablet} {
    font-size: 4.3vw;
  }
`;

export const BoxOutlineButton = styled.button<{
  color: string;
  arrow?: boolean;
}>`
  background: ${Colors.charcoal};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-size: 1.2vw;
  height: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.4vw;
  cursor: url(${pointer}), auto;
  svg {
    position: relative;
    transition: 250ms ease-out;
    left: 0;
  }
  :hover {
    svg {
      left: 0.6vw;
    }
    background: ${(props) => (props.arrow ? "" : props.color)};
    color: ${(props) => (props.arrow ? "" : "white")};
  }
  ${media.tablet} {
    font-size: 4.3vw;
    height: 18.1vw;
    padding: 0 7vw 0 4.8vw;
  }
`;
