import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import gsap from "gsap";

import { ReactComponent as IconSVG } from "svg/bitskiIcon.svg";
import { ReactComponent as MobileIconSVG } from "svg/mobileIcon.svg";

import { NavButton } from "./Buttons";
import mouse from "svg/mouseArrow.svg";
import pointer from "svg/pointer.svg";
import media from "src/styles/media";
import Colors from "src/styles/Colors";

const Header = () => {
  const [pathName, setPathName] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const wrapperRef: any = useRef();
  useEffect(() => {
    setPathName(location.pathname);
    gsap.to(wrapperRef.current, {
      opacity: 1,
      delay: location.pathname === "/pricing" ? 0 : 2,
      duration: location.pathname === "/pricing" ? 0 : 0.5,
    });
  }, []);
  return (
    <Wrapper ref={wrapperRef}>
      <Link to="/" aria-label="home">
        <Icon />
        <MobileIcon />
      </Link>
      <LinksWrapper>
        <LinkItem isacitve={pathName === "/" ? "true" : "false"} to="/">
          home
        </LinkItem>
        <LinkItem
          isacitve={pathName === "/pricing" ? "true" : "false"}
          to="/pricing"
        >
          pricing
        </LinkItem>
        <LinkItem to="https://www.notion.so/otl/Join-Bitski-26813e8c8234418e8984f9f2050a5a84">
          jobs
        </LinkItem>
      </LinksWrapper>
      <div style={{ display: "flex" }}>
        <a href="https://www.bitski.com/rtfkt/" style={{ marginRight: "1rem" }}>
          <Button
            style={{
              color: "white",
              backgroundColor: "#2926cf",
              width: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            RTFKT x STAPLE
          </Button>
        </a>
        <a href="https://www.bitski.com/adidas" style={{ marginRight: "1rem" }}>
          <Button
            style={{
              backgroundColor: "#4fd1f5",
              width: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            Trevor X adidas
          </Button>
        </a>
        <a
          href="https://www.bitski.com/livefeed"
          style={{ marginRight: "1rem" }}
        >
          <Button
            style={{
              backgroundColor: "black",
              color: "#f4f4f4",
              width: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            Live Feed
          </Button>
        </a>
        <a href="https://wallet.bitski.com">
          <Button
            style={{ paddingLeft: "1rem", paddingRight: "1rem", width: "auto" }}
          >
            My Wallet
          </Button>
        </a>
      </div>
      <MenuButton onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? "close" : "menu"}
      </MenuButton>
      <MobileMenu open={menuOpen}>
        <LinkItem isacitve={pathName === "/" ? "true" : "false"} to="/">
          home
        </LinkItem>
        <LinkItem
          isacitve={pathName === "/pricing" ? "true" : "false"}
          to="/pricing"
        >
          pricing
        </LinkItem>
        <LinkItem to="https://www.notion.so/otl/Join-Bitski-26813e8c8234418e8984f9f2050a5a84">
          jobs
        </LinkItem>
        <a href="https://www.bitski.com/rtfkt">
          <MobileButton
            style={{
              color: "white",
              backgroundColor: "#2926cf",
              bottom: "72vw",
            }}
          >
            RTFKT x STAPLE
          </MobileButton>
        </a>
        <a href="https://www.bitski.com/adidas">
          <MobileButton style={{ backgroundColor: "#4fd1f5", bottom: "56vw" }}>
            Trevor X adidas
          </MobileButton>
        </a>
        <a href="https://www.bitski.com/livefeed">
          <MobileButton
            style={{
              backgroundColor: "black",
              color: "#f4f4f4",
              bottom: "40vw",
            }}
          >
            Live Feed
          </MobileButton>
        </a>
        <a href="https://wallet.bitski.com">
          <MobileButton>My Wallet</MobileButton>
        </a>
      </MobileMenu>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  cursor: url(${mouse}), auto;

  height: 5.9vw;
  width: 100vw;
  max-width: 100%;
  padding: 0 6.9vw;
  display: flex;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(30, 30, 30, 0.75);
  position: absolute;
  top: 0;
  z-index: 5555;
  opacity: 0;
  ${media.tablet} {
    height: 20.3vw;
    background: rgba(30, 30, 30, 1);
    border-bottom: solid 1px white;
    padding: 0 4.8vw;
    opacity: 1;
    position: fixed;
  }
`;
const Icon = styled(IconSVG)`
  width: 2.7vw;
  cursor: url(${pointer}), auto;
  ${media.tablet} {
    display: none;
  }
`;
const MobileIcon = styled(MobileIconSVG)`
  width: 7.5vw;
  display: none;
  ${media.tablet} {
    display: block;
  }
`;
const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet} {
    display: none;
  }
`;
const LinkItem = styled(Link)<{ isacitve: string }>`
  font-size: 1.2vw;
  color: white;
  font-weight: normal;
  position: relative;
  line-height: 0.9444444444444444em;
  cursor: url(${pointer}), auto;
  margin-right: 5.2vw;
  &:last-child() {
    margin-right: 0;
  }
  ::after {
    width: 100%;
    height: 2px;
    background: ${(props) =>
      props.isacitve === "true" ? "#FF3568" : "transparent"};
    content: " ";
    position: absolute;
    left: 0;
    top: ${(props) => (props.isacitve === "true" ? "1.5vw" : "1.1vw")};
    transition: 200ms ease-out;
  }
  ${media.tablet} {
    font-size: 5.8vw;
    text-align: center;
    margin: 0 0 10.8vw;
    &:last-child() {
      margin-bottom: 0;
    }
    ::after {
      top: 105%;
    }
  }
`;
const Button = styled(NavButton)`
  cursor: url(${pointer}), auto;
  ${media.tablet} {
    display: none;
  }
`;
const MobileButton = styled(NavButton)`
  display: none;
  ${media.tablet} {
    display: block;
    width: 90.3vw;
    height: 12vw;
    position: absolute;
    bottom: 24vw;
    left: 4.8vw;
  }
`;
const MenuButton = styled.button`
  display: none;
  ${media.tablet} {
    display: block;
    // width: 17.6vw;
    height: 8vw;
    padding: 0 3.6vw;
    border-radius: 24.1vw;
    background: transparent;
    border: solid 1px white;
    font-family: "Mondwest";
    font-size: 4.5vw;
    color: white;
  }
`;
const MobileMenu = styled.nav<{ open: boolean }>`
  display: none;
  ${media.tablet} {
    display: ${(props) => (props.open ? "flex" : "none")};
    flex-direction: column;
    align-items: center;
    padding-top: 17.6vw;
    height: calc(100vh - 20.3vw);
    width: 100vw;
    position: absolute;
    top: 20.3vw;
    left: 0;
    background: ${Colors.charcoal};
  }
`;

export default Header;
