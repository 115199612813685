import React, { createContext, useState, useCallback, useEffect } from "react";

const ResizeContext = createContext();

const ResizeProvider = React.memo(({ children }) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const onResize = useCallback(() => {
    if (typeof window !== "undefined") {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
  }, []);
  useEffect(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return (
    <ResizeContext.Provider value={size}>{children}</ResizeContext.Provider>
  );
});

export { ResizeProvider, ResizeContext };
